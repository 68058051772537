import whenDomReady from 'when-dom-ready';
import { SELECTORS } from './selectors.js';

whenDomReady().then(() => {
    ProductSelectionShopLink.factory();
});

class ProductSelectionShopLink {
    static factory() {
        const instances = [];

        document.querySelectorAll(SELECTORS.container).forEach((container) => {
            instances.push(new ProductSelectionShopLink(container));
        });

        return instances;
    }

    /** @type {Element} */
    container = null;

    /** @type {HTMLAnchorElement} */
    link = null;

    /** @type {?Element} */
    productDisplay = null;

    /** @type {?Element} */
    yearDisplay = null;

    /** @type {?string} */
    selectedProduct = null;

    /** @type {?string} */
    selectedYear = null;

    constructor(container) {
        this.container = container;
        const { products, years } = this._findElements();
        this._listen(products, years);
        this._init(products, years);
    }

    _findElements() {
        const link = this.container.querySelector(SELECTORS.link);

        if (!link) {
            throw 'Invalid markup';
        }

        this.link = link;
        this.productDisplay = this.container.querySelector(
            SELECTORS.display.product
        );

        this.yearDisplay = this.container.querySelector(SELECTORS.display.year);

        const products = this.container.querySelectorAll(SELECTORS.product);
        const years = this.container.querySelectorAll(SELECTORS.year);

        return { products, years };
    }

    _init(products, years) {
        const firstProduct = products[0];
        const firstYear = years[0];

        this._onProductSelected(firstProduct);
        this._onYearSelected(firstYear);
    }

    /**
     * Attach event listeners.
     *
     * @private
     */
    _listen(products, years) {
        products.forEach((el) => {
            el.addEventListener('click', this._onProductClicked);
        });
        years.forEach((el) => {
            el.addEventListener('click', this._onYearClicked);
        });
    }

    _onProductClicked = (e) => {
        this._closeToggle(this.productDisplay);
        this._onProductSelected(e.target);
    };

    _onYearClicked = (e) => {
        this._closeToggle(this.yearDisplay);
        this._onYearSelected(e.target);
    };

    _closeToggle(display) {
        if (display && display.parentElement) {
            const parent = display.parentElement;
            const grandparent = parent?.parentElement;

            if (parent && parent.matches('.w-dropdown')) {
                parent.dispatchEvent(new CustomEvent('w-close'));
            }
            if (grandparent && grandparent.matches('.w-dropdown')) {
                grandparent.dispatchEvent(new CustomEvent('w-close'));
            }
        }
    }

    _onProductSelected(element) {
        const {
            ma,
            chorId = '',
            quantity = '',
            partnerId = '',
        } = element.dataset;

        this.selectedProduct = ma;

        if (this.productDisplay) {
            this._updateSelectionDisplay(
                this.productDisplay,
                element.innerText || ''
            );
        }
        this._updateLinkUrl({
            chorId,
            quantity,
            partnerid: partnerId,
        });
    }

    _onYearSelected(element) {
        this.selectedYear = element.dataset.year;

        if (this.yearDisplay) {
            this._updateSelectionDisplay(
                this.yearDisplay,
                element.innerText || ''
            );
        }
        this._updateLinkUrl();
    }

    _updateSelectionDisplay(displayElement, newLabel = '') {
        let displayName = '';

        if (displayElement.dataset.displayPrefix) {
            displayName += displayElement.dataset.displayPrefix;
        }

        displayName += newLabel.trim();

        displayElement.innerText = displayName;
    }

    _updateLinkUrl(updatedParams) {
        const url = new URL(this.link.href);
        const params = url.searchParams;

        params.set('ma', `${this.selectedProduct}${this.selectedYear}`);

        for (const name in updatedParams) {
            const val = updatedParams[name];
            params.set(name, val);
        }

        if (url.href !== this.link.href) {
            this.link.href = url.href;
        }
    }
}
