export const SELECTORS = {
    container: '[data-product-select]',
    product: '[data-ma]',
    year: '[data-year]',
    link: 'a.button-bestellung',
    display: {
        product: '[data-product-display]',
        year: '[data-year-display]',
    },
};
